var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pw-input"},[_c(_setup.TextInput,{attrs:{"type":"password","label":_vm.label,"placeholder":_vm.placeholder,"hide-details":""},on:{"focus":function($event){_setup.showMessage = true},"blur":function($event){_setup.showMessage = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.emit('enter')}},model:{value:(_setup.password),callback:function ($$v) {_setup.password=$$v},expression:"password"}}),_vm._v(" "),_c('div',{class:['pw-input__hint', { 'pw-input__hint--visible': _setup.showMessage || Boolean(_setup.message) }]},[_c('div',{staticClass:"pw-input__validation"},[_c('div',{staticClass:"pw-input__dots"},[_c('span',{class:[
            'dot',
            {
              'dot--red':
                (_setup.passwordStatus === 'too_short' && _setup.passwordLength < 6) ||
                _setup.passwordStatus === 'keyword' ||
                _setup.passwordStatus === 'old_password',
              'dot--orange':
                (_setup.passwordStatus === 'too_short' && _setup.passwordLength >= 6) ||
                _setup.passwordStatus === 'no_diff_chars' ||
                _setup.passwordStatus === 'pwned',
              'dot--green': _setup.passwordStatus === 'strong',
            },
          ]}),_vm._v(" "),_c('span',{class:[
            'dot',
            {
              'dot--red': _setup.passwordStatus === 'keyword' || _setup.passwordStatus === 'old_password',
              'dot--orange':
                (_setup.passwordStatus === 'too_short' && _setup.passwordLength >= 6) ||
                _setup.passwordStatus === 'no_diff_chars' ||
                _setup.passwordStatus === 'pwned',
              'dot--green': _setup.passwordStatus === 'strong',
            },
          ]}),_vm._v(" "),_c('span',{class:[
            'dot',
            {
              'dot--red': _setup.passwordStatus === 'keyword' || _setup.passwordStatus === 'old_password',
              'dot--green': _setup.passwordStatus === 'strong',
            },
          ]})]),_vm._v(" "),_c('div',{class:[
          'message',
          {
            'message--red':
              (_setup.passwordStatus === 'too_short' && _setup.passwordLength < 6) ||
              _setup.passwordStatus === 'keyword' ||
              _setup.passwordStatus === 'old_password',
            'message--orange':
              (_setup.passwordStatus === 'too_short' && _setup.passwordLength >= 6) ||
              _setup.passwordStatus === 'no_diff_chars' ||
              _setup.passwordStatus === 'pwned',
            'message--green': _setup.passwordStatus === 'strong',
          },
        ]},[_vm._v("\n        "+_vm._s(_setup.message)+"\n      ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(0 < _setup.passwordLength && _setup.passwordLength < 10),expression:"0 < passwordLength && passwordLength < 10"}],staticClass:"pw-input__counter"},[_vm._v(_vm._s(_setup.passwordLength)+" / 10")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }